import { useMemo } from 'react';
import {
    MaterialReactTable,
    MRT_ActionMenuItem,
    type MRT_ColumnDef,
} from 'material-react-table';
import { Icon, Message } from 'semantic-ui-react'
import moment from 'moment';

import { SpreadingGround, SpreadingGroundBasinTypeId } from "../../../models/SpreadingGround";

interface ISGUITableProps {
    spreadingGroundsList?: SpreadingGround[];
    editSG: (sg: SpreadingGround) => void;
    deleteSG: (sg: SpreadingGround) => void;
    viewCapture: (sg: SpreadingGround) => void;
}

const SGUITable: React.FC<ISGUITableProps> = (props) => {

    const lastUploadDate = useMemo(() => {
        const allUpdatedDates = props.spreadingGroundsList.flatMap(sg => sg.spreadingGroundCapture).map(x => new Date(x.updated));

        return allUpdatedDates.reduce((max, x) => x.getTime() > (max?.getTime() ?? 0) ? x : max, null);
    }, [props.spreadingGroundsList]);

    const columns = useMemo<MRT_ColumnDef<SpreadingGround>[]>(
        () => [
            {
                accessorKey: 'name',
                header: 'Spreading Ground Name',
                enableColumnFilter: false,
                sortDescFirst: true,
                size: 90,
            },
            {
                accessorKey: 'basinTypeId',
                header: 'Basin Type',
                enableColumnFilter: false,
                sortDescFirst: true,
                size: 90,
                accessorFn: (row) => SpreadingGroundBasinTypeId[row.basinTypeId],
            },
            {
                accessorKey: 'firstSeason',
                header: 'First Season',
                enableColumnFilter: false,
                sortDescFirst: true,
                size: 90,
            },
            {
                accessorKey: 'intakeCapacityCfs',
                header: 'Intake Capacity (cfs)',
                enableColumnFilter: false,
                sortDescFirst: true,
                size: 90,
            },
            {
                accessorKey: 'spreadingGroundCapture',
                header: 'Most Recent Event',
                enableColumnFilter: false,
                sortDescFirst: true,
                size: 90,
                accessorFn: (row) => {
                    if (row.spreadingGroundCapture.length === 0) return (<div> No Capture Events </div>);

                    const mostRecentUpdate = row.spreadingGroundCapture?.reduce((acuRecord, currentRecord) => {
                        return new Date(acuRecord.updated) > new Date(currentRecord.updated) ? acuRecord : currentRecord;
                    });

                    return <div>
                        <p>
                            {`${(moment(mostRecentUpdate?.spreadingGroundCaptureTimeFrame.startDate).format("MM-DD-YYYY"))} - ${moment(mostRecentUpdate?.spreadingGroundCaptureTimeFrame.endDate).format("MM-DD-YYYY")}`}
                        </p>
                        <p> {`Capture: ${mostRecentUpdate?.waterConservedAcft}`} ac-ft</p>
                    </div>
                },
            },
            {
                accessorKey: 'waterSourceDescription',
                header: 'Water Source',
                enableColumnFilter: false,
                sortDescFirst: true,
                size: 90,
            },

        ], []);

    const data = props.spreadingGroundsList;

    return (
        <div>
            <MaterialReactTable
                columns={columns}
                data={data}
                enableRowActions
                renderRowActionMenuItems={({ row, table, closeMenu }) => [
                    <MRT_ActionMenuItem
                        icon={<Icon disabled name='pencil' />}
                        key="edit"
                        label="Edit"
                        onClick={() => {
                            props.editSG(row.original);
                            closeMenu();
                        }
                        }
                        table={table}
                    />,
                    <MRT_ActionMenuItem
                        icon={<Icon disabled name='eye' />}
                        key="view"
                        label="View Capture"
                        onClick={() => {
                            props.viewCapture(row.original);
                            closeMenu();
                        }
                        }
                        table={table}
                    />,
                    <MRT_ActionMenuItem
                        icon={<Icon disabled name='trash' />}
                        key="delete"
                        label="Delete"
                        onClick={() => {
                            props.deleteSG(row.original);
                            closeMenu();
                        }
                        }
                        table={table}
                    />,
                ]}
                initialState={{
                    showColumnFilters: true,
                    pagination: { pageSize: 40, pageIndex: 0 },
                    //Leaving commented out for now, until we get confirmation from Dustin on styling 2024-04-10
                    // showGlobalFilter: true,
                }}
                enableStickyHeader={true}
                //Leaving commented out for now, until we get confirmation from Dustin on styling 2024-04-10
                // positionGlobalFilter="left"
                muiSearchTextFieldProps={{
                    placeholder: `Search Spreading Grounds`,
                    sx: { minWidth: '350px' },
                    variant: 'outlined',
                }}
                renderTopToolbarCustomActions={() => <Message style={{ marginBottom: 0 }}>
                    <p>
                        Last Upload: <b> {moment(lastUploadDate).format("MM-DD-YYYY")}</b>
                    </p>
                </Message>
                }
            />
        </div>
    );
};

export default SGUITable;